.card-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.card {
    background: #fff;
    padding: 12px 15px;
    box-shadow: 0 1px 3px 0px rgb(8 100 133 / 20%);
    position: relative;
}

.card-item {
    display: flex;
    flex-flow: column;
    margin-right: 10px;
    width: 100%;
    justify-content: flex-start;

    &.space-between {
        justify-content: space-between;
    }

    &:last-child {
        margin-right: 0px;
    }

    &.row {
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
    }

    &.vertical-spacing {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &.half {
        width: 50%;
    }

    &.one-quarter {
        width: 25%;
    }

    &.three-quarter {
        width: 75%;
    }

    &.one-third {
        width: (100% / 3);
    }

    &.two-third {
        width: (100% / 3 * 2);
    }

    &.empty {
        visibility: hidden;
    }
}
