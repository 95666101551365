.a-no-style {
    text-decoration: none;
    color: inherit;
    line-height: 0;
}

.flex-row {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-bottom: 25px;
}

// Progress bar
.default-progress {
    .ant-progress-inner {
        border-radius: 0px;

        .ant-progress-bg {
            height: 3px !important;
        }
    }
}

.filter-buttons {
    position: absolute;
    right: 30px;
    top: 115px;
    display: flex;
    align-items: center;
    height: 30px;

    > * {
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    .filter-icon {
        height: 14px;
        margin-top: -5px;

        &:hover {
            cursor: pointer;
        }
    }
}

.custom-button {
    text-transform: uppercase;
    font-family: RobotoMedium;
    padding: 2px 10px;
    border-radius: 4px !important;
    font-size: 13px;
    height: auto;
}
