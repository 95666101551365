.login-content {
    height: 100vh;
    background-image: url('../resources/images/login-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    width: 350px;
    background: #fff;
    display: flex;
    flex-flow: column;
    border-radius: 15px;
    background-image: url('../resources/images/login-modal-image.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
    padding-top: 230px;
    box-shadow: 5px 5px 10px 1px rgb(0 0 0 / 20%);

    .login-pos-logo {
        height: 35px;
    }

    .login-title {
        width: 100%;
        text-align: center;
        font-size: 20px;
        color: @gray;
    }

    .form-login-container {
        padding: 30px;
    }
}

.login-footer {
    justify-content: flex-end;
    background: rgba(0, 0, 0, 0%);
    border-top: 0px;

    .footer-copyright-text {
        color: #fff;
    }
}
