@font-face {
    font-family: 'RobotoRegular';
    src: local('RobotoRegular'), url(../resources/fonts/Roboto-Regular.ttf);
}

@font-face {
    font-family: 'RobotoBold';
    src: local('RobotoBold'), url(../resources/fonts/Roboto-Bold.ttf);
}

@font-face {
    font-family: 'RobotoMedium';
    src: local('RobotoMedium'), url(../resources/fonts/Roboto-Medium.ttf);
}

@font-face {
    font-family: 'RobotoLight';
    src: local('RobotoLight'), url(../resources/fonts/Roboto-Light.ttf);
}
