@scrollbar-size: 7px;

&::-webkit-scrollbar {
    width: @scrollbar-size;
    height: @scrollbar-size;
}

&::-webkit-scrollbar-track {
    background: #f0f0f0;
}

&::-webkit-scrollbar-thumb {
    background: #bbbbbb;
    border-radius: 4px;

    &:hover {
        background: #888888;
    }
}
