@role-hostess: #d73301;
@role-client: #4290e0;
@role-regional: #8bac26;
@role-coremember: #2f7637;
@role-backoffice: #e36a00;
@role-projectmanager: darkmagenta;
@role-finance: #4bb59b;

.users-role-container {
    display: flex;

    .user-role {
        color: #fff;
        background: #fff;
        margin-right: 10px;
        text-align: center;
        border-radius: 2px;
        padding: 0px 10px;
        white-space: nowrap;
        min-width: 120px;

        &.short {
            min-width: 40px;
        }

        &.hostess {
            background: @role-hostess;
        }

        &.customer {
            background: @role-client;
        }

        &.regional {
            background: @role-regional;
        }

        &.coremember {
            background: @role-coremember;
        }

        &.backoffice {
            background: @role-backoffice;
        }

        &.projectmanager {
            background: @role-projectmanager;
        }

        &.finance {
            background: @role-finance;
        }
    }
}

.user-role-dot {
    &:before {
        content: '⬤ ';
        font-size: 14px;
        line-height: 0;
    }

    &.hostess {
        &:before {
            color: @role-hostess;
        }
    }

    &.customer {
        &:before {
            color: @role-client;
        }
    }

    &.regional {
        &:before {
            color: @role-regional;
        }
    }

    &.coremember {
        &:before {
            color: @role-coremember;
        }
    }

    &.backoffice {
        &:before {
            color: @role-backoffice;
        }
    }

    &.projectmanager {
        &:before {
            color: @role-projectmanager;
        }
    }

    &.finance {
        &:before {
            color: @role-finance;
        }
    }
}
