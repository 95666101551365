.ant-table-thead > tr > th {
    background: #062b3b;
    color: #849fa9 !important;
    border-radius: 0px !important;
    border: none !important;

    &.ant-table-column-sort {
        background: #0a374a !important;
    }

    &.ant-table-column-has-sorters:hover {
        background: #0a374a !important;
    }
}

.ant-table {
    color: #68767a !important;
    font-size: 12px;

    .ant-table-cell {
        padding: 5px 16px;
    }

    .ant-table-container {
        border: none !important;

        table {
            border: none !important;
        }
    }
}

.ant-pagination {
    padding-right: 24px;
}

.scrollable-table, .ant-spin-nested-loading, .ant-spin-container {
    max-height: calc(100vh - 205px);

    .ant-table-body {
        max-height: calc(100vh - 288px) !important;
    }
}

.custom-pagination {
    .ant-pagination {
        position: absolute;
        bottom: 5px;
        right: 0;
        z-index: 2;
        margin: 4px 0;
    }
}

.table-nowrap {
    .ant-table-tbody {
        tr {
            td {
                white-space: nowrap;
            }
        }
    }

    .ant-table-thead > tr > th {
        white-space: nowrap;
    }
}

.pagination-row {
    height: 50px;
}

.table-height-40 {
    .ant-table-tbody {
        tr {
            height: 40px;
        }
    }
}

.pno-cell {
    font-weight: bold;
    white-space: nowrap;
}

.name-error {
    display: flex;
    align-items: center;
}

.error-cell {
    font-size: 16px;
    margin-right: 10px;
    line-height: 0.9;

    &.red {
        color: #f4001e;
    }

    &.yellow {
        color: #d99e1f;
    }

    &.hidden {
        visibility: hidden;
    }
}

.ammount-align {
    text-align: right !important;
}

.center-align {
    text-align: center !important;
}

.right-align {
    text-align: right !important;
}

.table-buttons-right-align {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.no-right-padding {
    padding-right: 0px !important;
}

.billed-cell {
    color: #8e9b9f;
    font-size: 18px;

    &.paid {
        color: #8bac26;
    }

    &.unpaid {
        color: #d73301;
    }
}

.buttons-container {
    display: flex;
    align-items: center;

    > * {
        &:not(:last-child) {
            margin-right: 25px;
        }
    }
}

.nested-table {
    margin-top: 11px;
    margin-bottom: 11px;

    thead, th {
        background: #eaf3fc !important;
    }

    tbody, td {
        background: #f7faff;
    }
}

.ant-table-expanded-row > td {
    background: #eaf3fc !important;
}

.ant-table-cell-scrollbar {
    box-shadow: none;
}

// Summary
.summary-cell {
    z-index: 10;
}

.summary-container {
    display: flex;
}

.summary-legend {
    display: flex;
    align-items: center;
}

.summary-legend-name {
    font-weight: bold;
}

.summary-legend-label {
    margin-right: 20px;
    padding-left: 2px;
    white-space: nowrap;
}
