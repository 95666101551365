@font-face {
  font-family: 'RobotoRegular';
  src: local('RobotoRegular'), url(../resources/fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: 'RobotoBold';
  src: local('RobotoBold'), url(../resources/fonts/Roboto-Bold.ttf);
}
@font-face {
  font-family: 'RobotoMedium';
  src: local('RobotoMedium'), url(../resources/fonts/Roboto-Medium.ttf);
}
@font-face {
  font-family: 'RobotoLight';
  src: local('RobotoLight'), url(../resources/fonts/Roboto-Light.ttf);
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  background: #f0f0f0;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #888888;
}
.card-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.card {
  background: #fff;
  padding: 12px 15px;
  box-shadow: 0 1px 3px 0px rgba(8, 100, 133, 0.2);
  position: relative;
}
.card-item {
  display: flex;
  flex-flow: column;
  margin-right: 10px;
  width: 100%;
  justify-content: flex-start;
}
.card-item.space-between {
  justify-content: space-between;
}
.card-item:last-child {
  margin-right: 0px;
}
.card-item.row {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.card-item.vertical-spacing:not(:last-child) {
  margin-bottom: 10px;
}
.card-item.half {
  width: 50%;
}
.card-item.one-quarter {
  width: 25%;
}
.card-item.three-quarter {
  width: 75%;
}
.card-item.one-third {
  width: 33.33333333%;
}
.card-item.two-third {
  width: 66.66666667%;
}
.card-item.empty {
  visibility: hidden;
}
.ant-table-thead > tr > th {
  background: #062b3b;
  color: #849fa9 !important;
  border-radius: 0px !important;
  border: none !important;
}
.ant-table-thead > tr > th.ant-table-column-sort {
  background: #0a374a !important;
}
.ant-table-thead > tr > th.ant-table-column-has-sorters:hover {
  background: #0a374a !important;
}
.ant-table {
  color: #68767a !important;
  font-size: 12px;
}
.ant-table .ant-table-cell {
  padding: 5px 16px;
}
.ant-table .ant-table-container {
  border: none !important;
}
.ant-table .ant-table-container table {
  border: none !important;
}
.ant-pagination {
  padding-right: 24px;
}
.scrollable-table,
.ant-spin-nested-loading,
.ant-spin-container {
  max-height: calc(100vh - 205px);
}
.scrollable-table .ant-table-body,
.ant-spin-nested-loading .ant-table-body,
.ant-spin-container .ant-table-body {
  max-height: calc(100vh - 288px) !important;
}
.custom-pagination .ant-pagination {
  position: absolute;
  bottom: 5px;
  right: 0;
  z-index: 2;
  margin: 4px 0;
}
.table-nowrap .ant-table-tbody tr td {
  white-space: nowrap;
}
.table-nowrap .ant-table-thead > tr > th {
  white-space: nowrap;
}
.pagination-row {
  height: 50px;
}
.table-height-40 .ant-table-tbody tr {
  height: 40px;
}
.pno-cell {
  font-weight: bold;
  white-space: nowrap;
}
.name-error {
  display: flex;
  align-items: center;
}
.error-cell {
  font-size: 16px;
  margin-right: 10px;
  line-height: 0.9;
}
.error-cell.red {
  color: #f4001e;
}
.error-cell.yellow {
  color: #d99e1f;
}
.error-cell.hidden {
  visibility: hidden;
}
.ammount-align {
  text-align: right !important;
}
.center-align {
  text-align: center !important;
}
.right-align {
  text-align: right !important;
}
.table-buttons-right-align {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.no-right-padding {
  padding-right: 0px !important;
}
.billed-cell {
  color: #8e9b9f;
  font-size: 18px;
}
.billed-cell.paid {
  color: #8bac26;
}
.billed-cell.unpaid {
  color: #d73301;
}
.buttons-container {
  display: flex;
  align-items: center;
}
.buttons-container > *:not(:last-child) {
  margin-right: 25px;
}
.nested-table {
  margin-top: 11px;
  margin-bottom: 11px;
}
.nested-table thead,
.nested-table th {
  background: #eaf3fc !important;
}
.nested-table tbody,
.nested-table td {
  background: #f7faff;
}
.ant-table-expanded-row > td {
  background: #eaf3fc !important;
}
.ant-table-cell-scrollbar {
  box-shadow: none;
}
.summary-cell {
  z-index: 10;
}
.summary-container {
  display: flex;
}
.summary-legend {
  display: flex;
  align-items: center;
}
.summary-legend-name {
  font-weight: bold;
}
.summary-legend-label {
  margin-right: 20px;
  padding-left: 2px;
  white-space: nowrap;
}
.content-container {
  padding-left: 200px;
}
.ant-layout-sider-trigger {
  background: none;
}
.ant-layout-sider-trigger span {
  background-image: url('../resources/images/menu_hamburger.png');
  width: 25px;
  height: 16px;
}
.ant-layout-sider-trigger span svg {
  display: none;
}
.ant-layout-sider {
  background: #032734;
  display: flex;
  flex-flow: column-reverse;
}
.ant-layout-sider .ant-layout-sider-trigger {
  width: inherit;
  position: fixed;
  top: 0px;
}
.ant-layout-sider.ant-layout-sider-collapsed .menu-container .ant-menu-item {
  padding: 0px 30px;
  display: flex;
  align-items: center;
}
.ant-layout-sider.ant-layout-sider-collapsed .menu-container .ant-menu-item .anticon {
  line-height: 0;
}
.ant-layout-sider .menu-container {
  width: 200px;
  position: fixed;
  top: 45px;
}
.ant-layout-sider .ant-menu-dark {
  background: #032734;
}
.ant-layout-header {
  height: 50px !important;
  padding: 0px !important;
  display: flex;
  align-items: center;
  background: rgba(5, 45, 63, 0.7) !important;
  position: absolute;
  width: 100%;
  font-size: 12px;
  border-bottom: 1px solid #06394c;
  line-height: 0px;
}
.ant-breadcrumb {
  font-size: 12px;
}
.ant-breadcrumb a {
  color: #869da6 !important;
  font-family: RobotoRegular;
}
.ant-breadcrumb-separator {
  color: #869da6 !important;
}
.ant-breadcrumb > span:last-child {
  color: #cc025d !important;
  font-family: RobotoRegular;
}
.logo-banner {
  height: 155px;
  min-height: 155px;
  background: url('../resources/images/banner.jpg');
  background-repeat: no-repeat;
  background-color: #052d3f;
  padding-left: 24px;
  padding-top: 115px;
}
.header-title {
  font-size: 20px;
  color: #fff;
  font-family: RobotoLight;
}
.site-layout,
.page-content {
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding-bottom: 40px;
}
.logout-icon {
  font-size: 18px;
}
.pos-logo {
  margin-left: 24px;
  margin-right: 20px;
  width: 125px;
}
.user-profile {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 30px;
  color: #869da6;
  font-family: RobotoRegular;
}
.user-name {
  margin-right: 30px;
}
.header-company-dropdown {
  margin-right: 30px;
}
.header-company-dropdown-body {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header-company-dropdown-body:hover {
  color: #40a9ff;
}
.header-company-dropdown-title {
  margin-right: 5px;
  cursor: inherit;
}
.header-company-dropdown-item {
  cursor: inherit;
}
.footer {
  height: 40px;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  min-width: inherit;
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
  background: #f6f8f9;
  border-top: 1px solid #ededed;
  font-size: 12px;
}
.footer .footer-important-text {
  font-weight: bold;
}
.footer .footer-important-text:after {
  content: ' ';
}
.footer-copyright-text {
  display: flex;
  color: rgba(0, 0, 0, 0.6);
}
.alg-logo {
  height: 16px;
  margin-left: 5px;
  margin-right: 5px;
}
.ant-layout-sider + .ant-layout .footer {
  margin-left: 200px;
  transition: all 0.2s;
}
.ant-layout-sider-collapsed + .ant-layout .footer {
  margin-left: 80px;
  transition: all 0.2s;
}
.a-no-style {
  text-decoration: none;
  color: inherit;
  line-height: 0;
}
.flex-row {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-bottom: 25px;
}
.default-progress .ant-progress-inner {
  border-radius: 0px;
}
.default-progress .ant-progress-inner .ant-progress-bg {
  height: 3px !important;
}
.filter-buttons {
  position: absolute;
  right: 30px;
  top: 115px;
  display: flex;
  align-items: center;
  height: 30px;
}
.filter-buttons > *:not(:first-child) {
  margin-left: 20px;
}
.filter-buttons .filter-icon {
  height: 14px;
  margin-top: -5px;
}
.filter-buttons .filter-icon:hover {
  cursor: pointer;
}
.custom-button {
  text-transform: uppercase;
  font-family: RobotoMedium;
  padding: 2px 10px;
  border-radius: 4px !important;
  font-size: 13px;
  height: auto;
}
.users-role-container {
  display: flex;
}
.users-role-container .user-role {
  color: #fff;
  background: #fff;
  margin-right: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 0px 10px;
  white-space: nowrap;
  min-width: 120px;
}
.users-role-container .user-role.short {
  min-width: 40px;
}
.users-role-container .user-role.hostess {
  background: #d73301;
}
.users-role-container .user-role.customer {
  background: #4290e0;
}
.users-role-container .user-role.regional {
  background: #8bac26;
}
.users-role-container .user-role.coremember {
  background: #2f7637;
}
.users-role-container .user-role.backoffice {
  background: #e36a00;
}
.users-role-container .user-role.projectmanager {
  background: darkmagenta;
}
.users-role-container .user-role.finance {
  background: #4bb59b;
}
.user-role-dot:before {
  content: '⬤ ';
  font-size: 14px;
  line-height: 0;
}
.user-role-dot.hostess:before {
  color: #d73301;
}
.user-role-dot.customer:before {
  color: #4290e0;
}
.user-role-dot.regional:before {
  color: #8bac26;
}
.user-role-dot.coremember:before {
  color: #2f7637;
}
.user-role-dot.backoffice:before {
  color: #e36a00;
}
.user-role-dot.projectmanager:before {
  color: darkmagenta;
}
.user-role-dot.finance:before {
  color: #4bb59b;
}
.ant-drawer-header {
  border-bottom: none;
  padding: 20px 40px;
  background: #f4f7fb;
}
.ant-drawer-header .ant-drawer-header-title {
  flex: 1;
  flex-flow: row-reverse;
  align-items: baseline;
}
.ant-drawer-header .ant-drawer-title {
  color: #063b4e;
  font-size: 28px;
  letter-spacing: -1px;
}
.ant-drawer-header .ant-drawer-title::after {
  font-size: 12px;
  color: #aaafb1;
  white-space: pre-line;
  display: block;
  line-height: 0.7;
  letter-spacing: 0px;
}
.ant-drawer-body {
  padding: 20px 40px;
  background: #f4f7fb;
}
.drawer-button-container {
  display: flex;
  justify-content: flex-start;
  padding: 20px 0px;
}
.drawer-button-container button:not(:last-child) {
  margin-right: 10px;
}
.st0 {
  fill: #FFFFFF;
}
.error-content {
  background: #5A657A;
  display: flex;
  justify-content: center;
}
.error-description-container {
  text-align: center;
  margin-top: 40px;
}
.error-title {
  color: #d7d7d7;
  font-size: 28px;
}
.error-description {
  color: #d7d7d7;
  font-size: 18px;
  text-align: center;
  width: 400px;
  font-family: Roboto;
}
.error-container {
  display: flex;
  flex-flow: column;
  align-items: center;
}
svg {
  display: block;
  margin: 0 auto;
  overflow: visible !important;
}
svg #robot_1_ {
  animation-name: verticalAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
}
svg #light_1_ {
  animation-name: blinkLight;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: steps(2);
}
svg #leftEye_1_,
svg #rightEye_1_ {
  animation-name: blinkEye;
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
  transform-origin: 50% 50%;
}
svg #leftArm_1_ {
  animation-name: moveLeftArm;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: 50% 10%;
  transition: all;
}
svg #rightArm_1_ {
  animation-name: moveRightArm;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: 50% 10%;
}
svg #shadow_1_ {
  animation-name: shadowAnimation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  transform-origin: 50%;
}
@keyframes wave {
  0% {
    transform: rotate(120deg);
  }
  100% {
    transform: rotate(170deg);
  }
}
@keyframes moveLeftArm {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(4deg);
  }
}
@keyframes moveRightArm {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-4deg);
  }
}
@keyframes shadowAnimation {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.15);
    opacity: 0.6;
  }
}
@keyframes verticalAnimation {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}
@keyframes blinkLight {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blinkEye {
  0% {
    transform: scaleY(0);
  }
  4% {
    transform: scaleY(1);
  }
}
.home-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.home-page-item-container {
  max-width: 1000px;
  padding: 20px 20px 20px 0px;
  display: flex;
  flex-flow: wrap;
  height: fit-content;
}
.home-item {
  width: 300px;
  height: 200px;
  display: flex;
  flex-flow: column;
  padding-bottom: 0px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.home-item .home-item-title {
  display: flex;
  height: 70%;
  flex-flow: column;
  text-align: center;
  margin-bottom: 5px;
  font-size: 18px;
  justify-content: center;
}
.home-item .home-item-title .home-item-badge sup {
  right: 110px;
}
.home-item .home-item-title .anticon {
  margin-bottom: 5px;
  font-size: 36px;
  color: #68767a;
}
.home-item .home-item-description {
  height: 80px;
  overflow: hidden;
  color: #aaafb1;
  font-size: 13px;
  line-height: 20px;
  display: none;
}
.home-item .home-item-footer {
  height: 30%;
  border-top: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.main-container {
  height: 100vh;
  background: #f8f9fa;
}
.financial-container {
  display: flex;
  height: 100%;
}
.financial-content-container {
  width: 85%;
  padding-right: 10px;
  overflow-y: scroll;
}
.financial-content-container .ant-table-wrapper {
  margin-bottom: 20px;
}
.financial-filter-container {
  width: 15%;
  background: #f4f7fc;
  padding: 20px;
  border-left: 1px solid #cbd6dc;
}
.financial-info-container {
  padding: 10px 0px 10px 10px;
}
.finance-table-title {
  background: #032734;
  color: #849fa9;
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
}
.financing-summary {
  height: 30px;
  font-weight: bold;
  background: #e0e0e1;
}
.financing-summary .ant-table-cell {
  font-size: 12px;
  border-bottom: 1px solid #e0e0e1;
}
.financing-table-total {
  background: #f0f2f5;
  font-weight: bold;
}
.status-locked-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dimension-select {
  display: flex;
  flex-flow: column;
}
.dimension-select > label {
  margin-bottom: 5px;
  border-radius: 4px !important;
  border-left-width: 1px;
}
.dimension-select > label::before {
  display: none !important;
}
.lock-switch .ant-switch-inner {
  margin-top: 1px;
  font-size: 16px;
}
.finance-campaign-detail-progress {
  margin: 0px 10px;
}
.timeline-progress {
  display: flex;
}
.timeline-progress:not(:last-child) {
  margin-right: 10px;
}
.login-content {
  height: 100vh;
  background-image: url('../resources/images/login-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-container {
  width: 350px;
  background: #fff;
  display: flex;
  flex-flow: column;
  border-radius: 15px;
  background-image: url('../resources/images/login-modal-image.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  padding-top: 230px;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.2);
}
.login-container .login-pos-logo {
  height: 35px;
}
.login-container .login-title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #aaafb1;
}
.login-container .form-login-container {
  padding: 30px;
}
.login-footer {
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0);
  border-top: 0px;
}
.login-footer .footer-copyright-text {
  color: #fff;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: RobotoRegular;
  overflow: hidden;
}
.title-line-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.title-line-label {
  font-size: 14px;
  font-family: RobotoBold;
  color: #68767a;
  white-space: nowrap;
  margin-right: 5px;
}
.title-line-hr {
  height: 1px;
  width: 100%;
  background-color: #dce4e7;
  border: none;
  border-bottom: 1px solid #fff;
  box-sizing: content-box;
}
.gray-small-label {
  font-size: 12px;
  color: #aaafb1;
}
.data-label {
  color: #68767a;
  font-family: RobotoMedium;
}
.data-label.small {
  font-size: 12px;
  font-family: RobotoRegular;
}
.data-label.bold {
  font-weight: bold;
}
.data-label.company-spacing {
  margin-bottom: 18px;
}
.filter-container {
  display: flex;
  flex-flow: column;
}
.filter-container .filter-item {
  display: flex;
  flex-flow: column;
}
.filter-container .filter-item:not(:last-child) {
  margin-bottom: 10px;
}
.financing-role-container {
  display: flex;
}
.financing-role-container .financing-role-dot {
  margin-right: 10px;
}
.financing-role-container .financing-role-dot.hostess {
  color: #e36a00;
}
.financing-role-container .financing-role-dot.coordinator {
  color: #9742e0;
}
