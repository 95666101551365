.main-container {
    height: 100vh;
    background: #f8f9fa;
}

.financial-container {
    display: flex;
    height: 100%;
}

.financial-content-container {
    width: 85%;
    padding-right: 10px;
    overflow-y: scroll;

    .ant-table-wrapper {
        margin-bottom: 20px;
    }
}

.financial-filter-container {
    width: 15%;
    background: #f4f7fc;
    padding: 20px;
    border-left: 1px solid #cbd6dc;
}

.financial-info-container {
    padding: 10px 0px 10px 10px;
}

.finance-table-title {
    background: #032734;
    color: #849fa9;
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
}

.financing-summary {
    height: 30px;
    font-weight: bold;
    background: #e0e0e1;

    .ant-table-cell {
        font-size: 12px;
        border-bottom: 1px solid #e0e0e1;
    }
}

.financing-table-total {
    background: #f0f2f5;
    font-weight: bold;
}

.status-locked-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dimension-select {
    display: flex;
    flex-flow: column;

    > label {
        margin-bottom: 5px;
        border-radius: 4px !important;
        border-left-width: 1px;

        &::before {
            display: none !important;
        }
    }
}

.lock-switch {
    .ant-switch-inner {
        margin-top: 1px;
        font-size: 16px;
    }
}

.finance-campaign-detail-progress {
    margin: 0px 10px;
}

.timeline-progress {
    display: flex;

    &:not(:last-child) {
        margin-right: 10px;
    }
}
