.ant-drawer-header {
    border-bottom: none;
    padding: 20px 40px;
    background: #f4f7fb;

    .ant-drawer-header-title {
        flex: 1;
        flex-flow: row-reverse;
        align-items: baseline;
    }

    .ant-drawer-title {
        color: #063b4e;
        font-size: 28px;
        letter-spacing: -1px;

        &::after {
            font-size: 12px;
            color: #aaafb1;
            white-space: pre-line;
            display: block;
            line-height: 0.7;
            letter-spacing: 0px;
        }
    }
}

.ant-drawer-body {
    padding: 20px 40px;
    background: #f4f7fb;
}


.drawer-button-container {
    display: flex;
    justify-content: flex-start;
    padding: 20px 0px;

    button:not(:last-child) {
        margin-right: 10px;
    }
}
