
.home-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.home-page-item-container {
    max-width: 1000px;
    padding: 20px 20px 20px 0px;
    display: flex;
    flex-flow: wrap;
    height: fit-content;
}

.home-item {
    width: 300px;
    height: 200px;
    display: flex;
    flex-flow: column;
    padding-bottom: 0px;
    margin-left: 20px;
    margin-bottom: 20px;

    .home-item-title {
        display: flex;
        height: 70%;
        flex-flow: column;
        text-align: center;
        margin-bottom: 5px;
        font-size: 18px;
        justify-content: center;

        .home-item-badge {
            sup {
                right: 110px;
            }
        }

        .anticon {
            margin-bottom: 5px;
            font-size: 36px;
            color: @gray-blue;
        }
    }

    .home-item-description {
        height: 80px;
        overflow: hidden;
        color: @gray;
        font-size: 13px;
        line-height: 20px;
        display: none;
    }

    .home-item-footer {
        height: 30%;
        border-top: 1px solid #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
